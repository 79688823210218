





import { Vue, Component ,Watch} from "vue-property-decorator";
import { faCoins } from '@fortawesome/free-solid-svg-icons';
@Component({})
export default class TimeLine extends Vue {
    style: { height: string } = {
        height: ""
    };

    setHeight() {
        let height: number = 0;
        const els = this.$slots.default;

        //@ts-ignore
        els.forEach(el => {
            //@ts-ignore
            if (Number(el.elm.clientHeight) > height) {
                //@ts-ignore
                height = Number(el.elm.clientHeight);
            }
        });
        
        this.style.height = `${height * 2 + 50}px`;
        console.log(this.style.height)
    }

    mounted() {
        this.setHeight();
    }

    
}
