












import { Vue, Component, Watch } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import TimeLine from "@/components/al/TimeLine.vue";
import TimeLineItem from "@/components/al/TimeLineItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
        "al-timeline": TimeLine,
        "al-timeline-item": TimeLineItem
    }
})
export default class OrganizePlan extends Vue {
    isshow = true;

    get lang() {
        return this.$i18n.locale;
    }

    get img_src() {
        const url_base =
            "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/";
        let url =
            this.$i18n.locale == "en"
                ? `${url_base}bestech_organizeplan_en.png`
                : `${url_base}bestech_organizeplan_zh.png`;
        return url;
    }

    @Watch("lang")
    onchangge(oval: any, nval: any) {
        this.isshow = false;
        this.$nextTick(() => {
            this.isshow = true;
        });
    }
}
