







































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import { HoverBox } from "@/components/al/hoverbox";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
        hoverbox: HoverBox
    }
})
export default class Standard extends Vue {
    get lang() {
        return this.$i18n.locale;
    }

    standard: any = [
        {
            haveBorder: true,
            footer: "入围标准",
            data: [
                {
                    title: "可参会",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kecanhui.png",
                    content:
                        "<p>创新技术项目供方具备派出代表参加以达成国际技术交易与更多形式创新技术产业化合作为目标的线上路演活动的意愿、可能性、可行性和适当人选。</p>",
                    isshow: false
                },
                {
                    title: "可跨境",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kekuajing.png",
                    content:
                        "<p>技术项目在供方和需方所在国家和地区，不存在任何跨境开展国际技术交易合作与创新技术产业化合作的障碍。</p>",
                    isshow: false
                },
                {
                    title: "可转化",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kezhuanhua.png",
                    content:
                        "<p>技术项目参加线上路演应以达成国际技术交易与更多形式创新技术产业化合作为目标，具备实现商业价值转化可行性，具备明确的市场与产业化应用前景。</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "5分",
            data: [
                {
                    title: "可交易",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kejiaoyi.png",
                    content:
                        "<p>技术项目应具有达成技术交易的明确合理的价格以及基于平等互利的商业合作条件，能提出明确可行的国际技术交易与创新技术产业化合作模式。</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "5分",
            data: [
                {
                    title: "可转移",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kezhuanyi.png",
                    content:
                        "<p>技术项目应能通过国际技术交易以及创新技术产业化合作，实现从有创新技术项目供方，转移至创新技术项目产业化合作一方。</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "5分",
            data: [
                {
                    title: "可落地",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/keluodi.png",
                    content:
                        "<p>技术项目应具备未来产业化落地的合作意愿与可行性。</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "80分",
            data: [
                {
                    title: "十大技术综合价值评审标准",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/jishuchuangxinxing.png",
                    content:
                        "<p>包括技术成熟度、技术先进度、技术创新度、经济价值、科学价值、社会价值、文化价值、知识产权保护、创新技术团队和预期风险。</p>",
                    isshow: false
                }
            ]
        }
    ];

    standard_en: any = [
        {
            haveBorder: true,
            footer: "Entry Criteria",
            data: [
                {
                    title: "Ability to Attend",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kecanhui.png",
                    content:
                        "<p>Suppliers of innovative tech projects have the willingness, availability, and appropriate representative to participate in online roadshows, which is designed to achieve international technology commercialization and promote cooperation.</p>",
                    isshow: false
                },
                {
                    title: "Ability for Cross-border Trade",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kekuajing.png",
                    content:
                        "<p>Between countries where suppliers and demanders are located, there is no barrier to carry out cross-border international technology trade and cooperation.</p>",
                    isshow: false
                },
                {
                    title: "Ability to Transform",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kezhuanhua.png",
                    content:
                        "<p>Participants in the online roadshow should aim for greater international commercialization, more forms of innovative technology cooperation, and higher commercial values with clear prospect of market and industrial application.</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "5 points",
            data: [
                {
                    title: "Ability to Trade",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kejiaoyi.png",
                    content:
                        "<p>Technology should have clear prices for technology trading and conditions for commercial cooperation based on mutual benefit, and clear and feasible cooperation models for international technology trading and industrialization of innovative technology can be proposed.</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "5 points",
            data: [
                {
                    title: "Ability to Transfer",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/kezhuanyi.png",
                    content:
                        "<p>Through international technology fair and technological industrialization, the technology suppliers are able to transfer  technology to the cooperatives of high-tech industrialization.</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "5 points",
            data: [
                {
                    title: "Ability to Implement",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/keluodi.png",
                    content:
                        "<p>The project teams should have the intention to cooperate and to implement the industrialization of their technology in the future.</p>",
                    isshow: false
                }
            ]
        },
        {
            footer: "80 points",
            data: [
                {
                    title: "Innovative technologies assessment: Ten major indicators",
                    img:
                        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/6ke/jishuchuangxinxing.png",
                    content:
                        "<p>Including Technology Readiness Level、Technology Effect Level、Technology Innovation Level、Economic Value、Scientific Value、Social Value、Cultural value、Intellectual Property Protection、Innovative Technology Team and Expected Risk.</p>",
                    isshow: false
                }
            ]
        }
    ];

    hoverout(el: any, item?: any) {
        if (item) {
            item.isshow = false;
        }
    }

    hoverin(el: any, item?: any) {
        if (item) {
            item.isshow = true;
        }
    }
}
