





















import { Vue, Component, Watch } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import TimeLine from "@/components/al/TimeLine.vue";
import TimeLineItem from "@/components/al/TimeLineItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
        "al-timeline": TimeLine,
        "al-timeline-item": TimeLineItem,
    },
})
export default class Process extends Vue {
    get timeline() {
        let timeline: {}[] = [
            {
                title: this.$t("home.process.item_1_title"),
                date: this.$t("home.process.item_1_date"),
                content: this.$t("home.process.item_1_content")
            },
            {
                title: this.$t("home.process.item_2_title"),
                date: this.$t("home.process.item_2_date"),
                content: this.$t("home.process.item_2_content")
            },
            {
                title: this.$t("home.process.item_3_title"),
                date: this.$t("home.process.item_3_date"),
                content: this.$t("home.process.item_3_content")
            },
            {
                title: this.$t("home.process.item_4_title"),
                date: this.$t("home.process.item_4_date"),
                content: this.$t("home.process.item_4_content")
            },
            {
                title: this.$t("home.process.item_5_title"),
                date: this.$t("home.process.item_5_date"),
                content: this.$t("home.process.item_5_content")
            },
        ];
        return timeline;
    }

    isshow = true;

    get lang() {
        return this.$i18n.locale;
    }

    @Watch("lang")
    onchangge(oval: any, nval: any) {
        this.isshow = false;
        this.$nextTick(() => {
            this.isshow = true;
        });
    }
}
