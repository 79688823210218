
















































































import { Vue, Component } from "vue-property-decorator";
import Carousel from "@/components/home/Carousel.vue";
import WhatsBestech from "@/components/home/WhatsBestech.vue";
import Organize from "@/components/home/Organize.vue";
import Process from "@/components/home/Process.vue";
// import Collect from "@/components/home/Collect.vue";
import Region from "@/components/home/Region.vue";
import Standard from "@/components/home/Standard.vue";
// import ZGC from "@/components/home/ZGC_old.vue";
import OrganizePlan from "@/components/home/OrganizePlan.vue";
import DownloadFile from "@/components/home/DownloadFile.vue";
import Contact from "@/components/home/Contact.vue";
import { RegistMethod } from "@/components/home/registMethod";
import Expert from "@/components/home/activityReview_2022/Expert.vue";

@Component({
  components: {
    carousel: Carousel,
    WhatsBestech,
    Organize,
    Process,
    // Collect,
    Region,
    Standard,
    // zgc: ZGC,
    "organize-plan": OrganizePlan,
    "download-file": DownloadFile,
    Contact,
    RegistMethod,
    Expert: Expert,
  },
})
export default class Home extends Vue {}
