












































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import { HoverBox } from "@/components/al/hoverbox";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
    hoverbox: HoverBox,
  },
})
export default class Collect extends Vue {
  get lang() {
    return this.$i18n.locale;
  }

  region: any = [
    {
      title: "新一代信息技术",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinyidaixinxijishu.png",
      content:
        "<p>人工智能、先进通信网络、超高清视频和新型显示、网络安全与信创、北斗、虚拟现实、信息通信设备、操作系统与工业软件、智能制造核心信息设备、区块链与先进计算、智慧城市等</p>",
      isshow: false,
    },
    {
      title: "生物技术与生命科学",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/shengwujishu.png",
      content:
        "<p>给药系统、生物大分子鉴定和序列读取技术、合成生物技术、新一代基因操作技术、新型细胞治疗、干细胞与再生医学、新一代生物检测技术、微生物组技术、纳米生物技术、生物影像技术、生物大数据、组学技术、AI医药研发等</p>",
      isshow: false,
    },
    {
      title: "创新药与大健康",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024chuangxinhao.png",
      content:
        "<p>疫苗、抗体药物、生长激素、核酸药物、中药、重组蛋白、首仿药和高端仿制药、血液制品等</p>",
      isshow: false,
    },
    {
      title: "创新医疗器械",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanyiliao.png",
      content:
        "<p>高值耗材、高端医疗影像设备、临床检验设备、诊断检验装备、监护与生命支持装备、健康监测、远程医疗和康复设备、中医诊疗装备、妇幼健康装备、植介入器械等</p>",
      isshow: false,
    },
    {
      title: "智能终端与高端装备",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanzhuangbei.png",
      content:
        "<p>高档数控机床和机器人、航空航天装备、海洋工程装备及高技术船舶、先进轨道交通、节能与新能源汽车、电力装备、光电子领域、新型存储器、集成电路等</p>",
      isshow: false,
    },
    {
      title: "智能生产与先进制造",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/zhinengzhizao.png",
      content:
        "<p>智能制造系统解决方案、数字化车间、智能工厂、光电显示制造、工业装备再制造、智能专用设备等</p>",
      isshow: false,
    },
    {
      title: "新能源及应用",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinnengyuan.png",
      content:
        "<p>氢能、太阳能、智能电网、智能电网、清洁供热、能源互联网、新能源汽车、环保能源、生物质能及清洁高效利用等</p>",
      isshow: false,
    },
    {
      title: "碳中和与绿色创新",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024tanzhonghe.png",
      content:
        "<p>采矿业、技术固碳，碳捕捉、利用与封存技术、烟气CO2捕捉、电力生产、废弃物处理、碳检测等</p>",
      isshow: false,
    },
    {
      title: "新材料及应用",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024xincailiao.png",
      content:
        "<p>特种金属功能材料、高端金属结构材料、先进高分子材料、新型无机非金属材料、高性能复合材料、超导材料、磁性材料、化工新材料、生态环境材料等</p>",
      isshow: false,
    },
    {
      title: "现代农业",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xiandainongye.png",
      content:
        "<p>农副产品制造、农业生物技术、核农业技术、设施农业技术、移植、常规技术组装配套、生物育种、畜禽水产健康养殖与疫病防控、农产品精深加工与现代储运、农林生物质综合开发利用、环保型肥料、农药创新等</p>",
      isshow: false,
    },
  ];

  region_en: any = [
    {
      title: "New Generation of ICT",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinyidaixinxijishu.png",
      content:
        "<p>Artificial Intelligence, advanced communication networks, ULTRA-high definition video and new display, network security and ict, beidou, virtual reality, information communication equipment, operating system and industrial software, intelligent manufacturing core information equipment, blockchain and advanced computing, smart city etc.</p>",
      isshow: false,
    },
    {
      title: "Technology and Life Sciences",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/shengwujishu.png",
      content:
        "<p>Drug delivery system, identification and biological macromolecules sequence read technology, synthetic biology, a new generation of genetic manipulation technology, the new type of cell therapy, stem cell and regenerative medicine, a new generation of biological detection technology, microbial technology, nano biotechnology, biological imaging techniques, biological big data, group technology, AI medicine research and development, etc</p>",
      isshow: false,
    },
    {
      title: "Innovative Drug and Comprehensive Health",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024chuangxinhao.png",
      content:
        "<p>Vaccine, antibody drugs, growth hormone, nucleic acid drugs, Traditional Chinese medicine, recombinant protein, first generic drugs and high-end generic drugs, blood products, etc</p>",
      isshow: false,
    },
    {
      title: "Medical Device Innovation",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanyiliao.png",
      content:
        "<p>High-value consumables, high-end medical imaging equipment, clinical testing equipment, diagnostic testing equipment, monitoring and life support equipment, health monitoring, telemedicine and rehabilitation equipment, TCM diagnosis and treatment equipment, maternal and child health equipment, implant intervention equipment, etc</p>",
      isshow: false,
    },
    {
      title: "Smart Device and High-ended Equipment ",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanzhuangbei.png",
      content:
        "<p>High-end CNC machine tools and robots, aerospace equipment, ocean engineering equipment and high-tech ships, advanced rail transit, energy-saving and new energy vehicles, electric power equipment, optoelectronics field, new memory, integrated circuits, etc</p>",
      isshow: false,
    },
    {
      title: "Smart Industry and Advanced Processing",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/zhinengzhizao.png",
      content:
        "<p>Intelligent manufacturing system solutions, digital workshop, intelligent factory, photoelectric display manufacturing, industrial equipment remanufacturing, intelligent special equipment, etc</p>",
      isshow: false,
    },
    {
      title: "New Energy and Applying Innovation",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinnengyuan.png",
      content:
        "<p>Hydrogen energy, solar energy, smart grid, smart grid, clean heating, energy Internet, new energy vehicles, environmental protection energy, biomass energy and clean and efficient utilization, etc</p>",
      isshow: false,
    },
    {
      title: "Carbon Neutrality and Green-oriented Innovation",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024tanzhonghe.pngg",
      content:
        "<p>Mining, technology carbon sequestration, carbon capture, utilization and storage technology, flue gas CO2 capture, electric power production, waste treatment, carbon detection, etc</p>",
      isshow: false,
    },
    {
      title: "New Material and Applying Innovation",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024xincailiao.png",
      content:
        "<p>Special metal functional materials, high-end metal structure materials, advanced polymer materials, new inorganic non-metallic materials, high-performance composite materials, superconducting materials, magnetic materials, new chemical materials, ecological environment materials, etc</p>",
      isshow: false,
    },
    {
      title: "Modern Agriculture",
      img:
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xiandainongye.png",
      content:
        "<p>Agricultural and sideline product manufacturing, agricultural biotechnology, nuclear agricultural technology, facility agricultural technology, transplantation, conventional technology assembly and matching, biological breeding, livestock, poultry and aquatic health breeding and disease prevention and control, intensive processing and modern storage and transportation of agricultural products, comprehensive development and utilization of agricultural and forestry biomass, environmental protection fertilizer, pesticide innovation, etc</p>",
      isshow: false,
    },
  ];

  hoverout(el: any, item?: any) {
    if (item) {
      item.isshow = false;
    }
  }

  hoverin(el: any, item?: any) {
    if (item) {
      item.isshow = true;
    }
  }
}
