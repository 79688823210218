













import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class TimeLineItem extends Vue {
    @Prop({ type: String, required: false, default: () => "" })
    title!: String; //标题

    @Prop({ type: String, required: false, default: () => "" })
    date!: String; //日期

    @Prop({ type: String, required: false, default: () => false })
    content!: string | boolean; //内容

    @Prop({ type: String, required: false, default: () => "" })
    title_class!: string;

    @Prop({ type: String, required: false, default: () => "" })
    date_class!: string;

    @Prop({ type: String, required: false, default: () => "" })
    content_class!: string;
}
