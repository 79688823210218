


























import { Vue, Component } from "vue-property-decorator";
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  Scrollbar,
  EffectCoverflow,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
import { Getter } from "vuex-class";

SwiperClass.use([
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  Scrollbar,
  EffectCoverflow,
]);
Vue.use(getAwesomeSwiper(SwiperClass));

@Component({
  components: {},
})
export default class Carousel extends Vue {
  @Getter("getUser") getUser: any;

  swiperOption = {
    direction: "horizontal",
    observer: true, //修改swiper自己或子元素时，自动初始化swiper
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    // slidesPerView: 7,
    // spaceBetween: 10,
    centeredSlides: false,
    centeredSlidesBounds: false,
    loop: true,
    speed: 800,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
      hide: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      hideOnClick: true,
    },
  };
  get swiperData() {
    const that = this;
    let opt = [
      {
        url:
          this.$i18n.locale == "zh"
            ? "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024bcn.jpg"
            : "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024ben.png",
        params: {
          step: "1",
          pid:
            this.getUser("project_id") !== ""
              ? this.getUser("project_id")
              : undefined,
        },
        click(item: any, i: any, params?: any) {
          that.$router.push({
            name: "register",
            params: params,
          });
        },
      },
      {
        url:
          this.$i18n.locale == "zh"
            ? "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024zhuanjiacn.png"
            : "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/2024zhuanjiaen.png",
      },
      {
        url:
          this.$i18n.locale == "zh"
            ? "http://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/quanyicn.jpg"
            : "http://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/quanyien.jpg",
      },
      /*
      {
        url:
          this.$i18n.locale == "zh"
            ? "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/banner_2022/bestech_banner_4_zh.jpg"
            : "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/banner_2022/bestech_banner_4_en.jpg",
      },
      {
        url:
          this.$i18n.locale == "zh"
            ? "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/banner_2022/bestech_banner_5_zh.jpg"
            : "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/banner_2022/bestech_banner_5_en.jpg",
      },
      */
      // {
      //     url:
      //         this.$i18n.locale == "zh"
      //             ? "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/bestech_banner_1_zh.jpg"
      //             : "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/bestech_banner_1_en.jpg",
      //     click(item: any, i: any) {
      //         that.$router.push({
      //             name: "register",
      //             params: { step: "0" }
      //         });
      //     }
      // },
    ];
    return opt;
  }
  iskeepAutoplay: boolean = true;

  handleImg(item: any, i: any) {
    item.click ? item.click(item, i, item.params) : false;
    // if (item.click) {
    //     item.click(item, i);
    // }
  }
}
