


























































import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import "@/components/al/ImportJs";

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  Scrollbar,
  EffectCoverflow,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
SwiperClass.use([
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  Scrollbar,
  EffectCoverflow,
]);
Vue.use(getAwesomeSwiper(SwiperClass));

import CaseShow2021 from "@/components/home/2021CaseShow.vue";
import ExpertShow2021 from "@/components/home/2021ExpertShow.vue";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
    CaseShow2021,
    ExpertShow2021,
  },
})
export default class WhatsBestech extends Vue {
  @Getter("getLang") lang: any;

  video_playerId = "video_player"; //视频播放器id
  video_coverImg =
    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E7%99%BE%E5%BC%BA%E8%A7%86%E9%A2%91%E5%B0%81%E9%9D%A2.png"; //视频封面
  video_url =
    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E7%99%BE%E5%BC%BA.mp4"; //视频url

  video_playerId_1 = "test";

  showProjectSwiper: boolean = true; //是否显示项目列表轮播

  //初始化视频播放器
  setVideo(playerId: any, url: any, coverImg: any, mute?: boolean) {
    //初始化视频
    // @ts-ignore
    var player = new Aliplayer(
      {
        id: playerId,
        width: "100%",
        height: "100%",
        autoplay: false,
        cover: coverImg,
        source: url,
        isLive: false,
        rePlay: true,
        playsinline: true,
        preload: true,
        controlBarVisibility: "hover",
        useH5Prism: true,
      },
      function(player: any) {
        // console.log("关于ITCP培训模块，视频播放器创建好了。");
        if (mute) {
          player.mute();
        }
      }
    );
  }

  //外部js文件加载完毕钩子
  isLoaded(e: any) {
    this.setVideo(this.video_playerId, this.video_url, this.video_coverImg);
    // this.setVideo(this.video_playerId_1,"https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/x100%E5%90%8D%E5%8D%95HD.mp4",this.video_coverImg,true)
  }

  timing: any;
  @Watch("lang")
  onChangeLang(nval: any, oval: any) {
    this.showProjectSwiper = false;
    this.timing = setTimeout(() => {
      this.showProjectSwiper = true;
    }, 50);
  }

  destroyed() {
    clearTimeout(this.timing);
    this.timing = "";
  }
}
