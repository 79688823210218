





















import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
  },
})
export default class DownloadFile extends Vue {
  get list() {
    let opt: {}[] = [
      {
        url: this.$t("home.downloadFile.item_1_url"),
        name: this.$t("home.downloadFile.item_1_name"),
      },
      {
        url: this.$t("home.downloadFile.item_2_url"),
        name: this.$t("home.downloadFile.item_2_name"),
      },
      {
        url: this.$t("home.downloadFile.item_3_url"),
        name: this.$t("home.downloadFile.item_3_name"),
      },
      /*
            {
                url: this.$t("home.downloadFile.item_4_url"),
                name: this.$t("home.downloadFile.item_4_name")
            },
            */
      {
        url: this.$t("home.downloadFile.item_5_url"),
        name: this.$t("home.downloadFile.item_5_name"),
      },
      {
        url: this.$t("home.downloadFile.item_6_url"),
        name: this.$t("home.downloadFile.item_6_name"),
      },
    ];
    return opt;
  }
}
